import React from "react";


function Products() {

    return (
        <div className="detail-box">      
<h3 align="center">Products</h3>

<div class="product-row">
  <div class="column left"> <a href="mha_product_pack.png" target="_blank" rel="noreferrer"><img alt="My Hero Academia" class="product-image" src="mha_product_pack.png"/></a></div>
  <div class="column right"><h4><b>My Hero Academia Series 1 Foil Pack</b></h4>
  <p><b>Release Date</b>: 7/15/23<br/><b>UPC</b>: 810090 375335<br/><b>Item #</b>: CBR-0001<br/>Each Foil Pack contains 3 cels.</p></div>
</div>
<div class="product-row">
  <div class="column left"><a href="mha_product_box.png" target="_blank" rel="noreferrer"><img alt="My Hero Academia" class="product-image" src="mha_product_box.png"/></a></div>
  <div class="column right"><h4><b>My Hero Academia Series 1 Display Box</b></h4>
  <p><b>Release Date</b>: 7/15/23<br/><b>UPC</b>: 810090375342<br/><b>Item #</b>: CBR-0002<br/>Each Display Box contains 20 Foil Packs and 1 XL cel.</p>
  <p><b>Buy Here</b>: 
  <a href="https://www.popsandpins.com/collections/cybercel/products/cybercel-my-hero-academia-pre-order-eta-june-2023/?ref=WBPMzPaO" target="_blank" rel="noreferrer"><img alt="Pops and Pins" class="buy-image" src="buy_popspins.png"/></a>
  <a href="https://www.threeifbyspace.net/product/2023-cybercel-my-hero-academia-trading-cards-hobby/?ref=515" target="_blank" rel="noreferrer"><img alt="Three if by Space" class="buy-image" src="buy_tibs.png"/></a>
  <a href="https://ralphiesfunhouse.com/products/preorder-estimated-arrival-q3-2023-cybercel-series-1-2023-my-hero-academia-trading-cards-sealed-carton/?ref=z6ju5rg3js" target="_blank" rel="noreferrer"><img alt="Ralphie's Funhouse" class="buy-image" src="buy_ralphies.png"/></a>
  <a href="https://www.entertainmentearth.com/product/fgcbr0001aa?id=P0MJ53HDFA" target="_blank" rel="noreferrer"><img alt="Entertainment Earth" class="buy-image" src="buy_ee.png"/></a>
  </p>
  </div>
</div>

<div class="product-row">
  <div class="column left"><a href="jjk_product_pack.png" target="_blank" rel="noreferrer"><img alt="Jujutsu Kaisen" class="product-image" src="jjk_product_pack.png"/></a></div>
  <div class="column right"><h4><b>Jujutsu Kaisen Series 1 Foil Pack</b></h4>
  <p><b>Release Date</b>: 8/25/23<br/><b>UPC</b>: 810090375588<br/><b>Item #</b>: CBR-0005<br/>Each Foil Pack contains 3 cels.</p></div>
</div>
<div class="product-row">
  <div class="column left"><a href="jjk_product_box.png" target="_blank" rel="noreferrer"><img alt="Jujutsu Kaisen" class="product-image" src="jjk_product_box.png"/></a></div>
  <div class="column right"><h4><b>Jujutsu Kaisen Series 1 Display Box</b></h4>
  <p><b>Release Date</b>: 8/25/23<br/><b>UPC</b>: 810090375595<br/><b>Item #</b>: CBR-0006<br/>Each Display Box contains 20 Foil Packs and 1 XL cel.</p>
  <p><b>Buy Here</b>: 
  <a href="https://www.popsandpins.com/collections/cybercel/products/cybercel-my-hero-academia-pre-order-eta-june-2023/?ref=WBPMzPaO" target="_blank" rel="noreferrer"><img alt="Pops and Pins" class="buy-image" src="buy_popspins.png"/></a>
  <a href="https://www.threeifbyspace.net/product/2023-cybercel-my-hero-academia-trading-cards-hobby/?ref=515" target="_blank" rel="noreferrer"><img alt="Three if by Space" class="buy-image" src="buy_tibs.png"/></a>
  <a href="https://ralphiesfunhouse.com/products/preorder-estimated-arrival-q3-2023-cybercel-series-1-2023-my-hero-academia-trading-cards-sealed-carton/?ref=z6ju5rg3js" target="_blank" rel="noreferrer"><img alt="Ralphie's Funhouse" class="buy-image" src="buy_ralphies.png"/></a>
  <a href="https://www.entertainmentearth.com/product/fgcbr0005aa?id=P0MJ53HDFA" target="_blank" rel="noreferrer"><img alt="Entertainment Earth" class="buy-image" src="buy_ee.png"/></a>
  </p>
  </div>
</div>

<div class="product-row">
  <div class="column left"><a href="csm_product_pack.png" target="_blank" rel="noreferrer"><img alt="Chainsaw Man" class="product-image" src="csm_product_pack.png"/></a></div>
  <div class="column right"><h4><b>Chainsaw Man Series 1 Foil Pack</b></h4>
  <p><b>Release Date</b>: 10/6/23<br/><b>UPC</b>: 810090375601<br/><b>Item #</b>: CBR-0007<br/>Each Foil Pack contains 3 cels.</p></div>
</div>
<div class="product-row">
  <div class="column left"><a href="csm_product_box.png" target="_blank" rel="noreferrer"><img alt="Chainsaw Man" class="product-image" src="csm_product_box.png"/></a></div>
  <div class="column right"><h4><b>Chainsaw Man Series 1 Display Box</b></h4>
  <p><b>Release Date</b>: 10/6/23<br/><b>UPC</b>: 810090375618<br/><b>Item #</b>: CBR-0008<br/>Each Display Box contains 20 Foil Packs and 1 XL cel.</p>
  <p><b>Buy Here</b>: 
  <a href="https://www.entertainmentearth.com/product/fgcbr0007aa?id=P0MJ53HDFA" target="_blank" rel="noreferrer"><img alt="Entertainment Earth" class="buy-image" src="buy_ee.png"/></a>
  <a href="https://www.threeifbyspace.net/product/chainsaw-man-hobby-box-cybercel-2023/?ref=515" target="_blank" rel="noreferrer"><img alt="Three if by Space" class="buy-image" src="buy_tibs.png"/></a>
  </p>
  </div>
</div>


<div class="product-row">
  <div class="column left"><a href="sxf_product_pack.png" target="_blank" rel="noreferrer"><img alt="Spy X Family" class="product-image" src="sxf_product_pack.png"/></a></div>
  <div class="column right"><h4><b>Spy X Family Series 1 Foil Pack</b></h4>
  <p><b>Release Date</b>: 9/27/23<br/><b>UPC</b>: 810090375564<br/><b>Item #</b>: CBR-0003<br/>Each Foil Pack contains 3 cels.</p></div>
</div>
<div class="product-row">
  <div class="column left"><a href="sxf_product_box.png" target="_blank" rel="noreferrer"><img alt="Spy X Family" class="product-image" src="sxf_product_box.png"/></a></div>
  <div class="column right"><h4><b>Spy X Family Series 1 Display Box</b></h4>
  <p><b>Release Date</b>: 9/27/23<br/><b>UPC</b>: 810090375571<br/><b>Item #</b>: CBR-0004<br/>Each Display Box contains 20 Foil Packs and 1 XL cel.</p>
  <p><b>Buy Here</b>: 
  <a href="https://www.entertainmentearth.com/product/fgcbr0003aa?id=P0MJ53HDFA" target="_blank" rel="noreferrer"><img alt="Entertainment Earth" class="buy-image" src="buy_ee.png"/></a>
  <a href="https://www.threeifbyspace.net/product/spy-x-family-hobby-box-cybercel-2023/?ref=515" target="_blank" rel="noreferrer"><img alt="Three if by Space" class="buy-image" src="buy_tibs.png"/></a>
  </p>
  </div>
</div>

<div class="product-row">
  <div class="column left"><a href="dbs_product_pack.png" target="_blank" rel="noreferrer"><img alt="Dragon Ball Super" class="product-image" src="dbs_product_pack.png"/></a></div>
  <div class="column right"><h4><b>Dragon Ball Super Series 1 Foil Pack</b></h4>
  <p><b>Release Date</b>: 11/30/23<br/><b>UPC</b>: 810090377735<br/><b>Item #</b>: CBR-0015<br/>Each Foil Pack contains 3 cels.</p></div>
</div>
<div class="product-row">
  <div class="column left"><a href="dbs_product_box.png" target="_blank" rel="noreferrer"><img alt="Dragon Ball Super" class="product-image" src="dbs_product_box.png"/></a></div>
  <div class="column right"><h4><b>Dragon Ball Super Series 1 Display Box</b></h4>
  <p><b>Release Date</b>: 11/30/23<br/><b>UPC</b>: 810090377742<br/><b>Item #</b>: CBR-0016<br/>Each Display Box contains 20 Foil Packs and 1 XL cel.</p>
  <p><b>Buy Here</b>: 
  <a href="https://www.threeifbyspace.net/product/dragonball-z-super-trading-cards-hobby-cybercel-2023/?ref=515" target="_blank" rel="noreferrer"><img alt="Three if by Space" class="buy-image" src="buy_tibs.png"/></a>
  </p>
  </div>
</div>

<div class="product-row">
  <div class="column left"><a href="cpe_product_pack.png" target="_blank" rel="noreferrer"><img alt="Cyberpunk Edgerunners" class="product-image" src="cpe_product_pack.png"/></a></div>
  <div class="column right"><h4><b>Cyberpunk Edgerunners Series 1 Foil Pack</b></h4>
  <p><b>Release Date</b>: 11/30/23<br/><b>UPC</b>: 810090377711<br/><b>Item #</b>: CBR-0013<br/>Each Foil Pack contains 3 cels.</p></div>
</div>
<div class="product-row">
  <div class="column left"><a href="cpe_product_box.png" target="_blank" rel="noreferrer"><img alt="Cyberpunk Edgerunners" class="product-image" src="cpe_product_box.png"/></a></div>
  <div class="column right"><h4><b>Cyberpunk Edgerunners Series 1 Display Box</b></h4>
  <p><b>Release Date</b>: 11/30/23<br/><b>UPC</b>: 810090377728<br/><b>Item #</b>: CBR-0014<br/>Each Display Box contains 20 Foil Packs and 1 XL cel.</p>
  <p><b>Buy Here</b>: 
  <a href="https://www.threeifbyspace.net/product/cyberpunk-edgerunners-hobby-box-cybercel-2023/?ref=515" target="_blank" rel="noreferrer"><img alt="Three if by Space" class="buy-image" src="buy_tibs.png"/></a>
  </p>
  </div>
</div>

<div class="product-row">
  <div class="column left"><a href="fnf_product_pack.png" target="_blank" rel="noreferrer"><img alt="Five Nights at Freddy’s" class="product-image" src="fnf_product_pack.png"/></a></div>
  <div class="column right"><h4><b>Five Nights at Freddy’s Series 1 Foil Pack</b></h4>
  <p><b>Release Date</b>: October 2023<br/><b>UPC</b>: 810090377759<br/><b>Item #</b>: CBR-0027<br/>Each Foil Pack contains 3 cels.</p></div>
</div>
<div class="product-row">
  <div class="column left"><a href="fnf_product_box.png" target="_blank" rel="noreferrer"><img alt="Five Nights at Freddy’s" class="product-image" src="fnf_product_box.png"/></a></div>
  <div class="column right"><h4><b>Five Nights at Freddy’s Series 1 Display Box</b></h4>
  <p><b>Release Date</b>: October 2023<br/><b>UPC</b>: 810090377766<br/><b>Item #</b>: CBR-0028<br/>Each Display Box contains 20 Foil Packs and 1 XL cel.</p>
  </div>
</div>


<br/>
<p align="center" className="legaltext">CybercelDB is user supported. When you purchase through links on our site, we may earn an affiliate commission.</p>

        </div>
    );
}

export default Products;