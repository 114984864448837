
import React, { useState, useEffect } from "react";
import CelDataService from "../services/cel";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import '../App.css'

const CelListSubset = () => {
  let { by, query } = useParams();
const [cels, setCels] = useState([]);

const getCel = (by, query) => {
    CelDataService.find(by, query)
      .then(response => {
        setCels(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  
  useEffect(() => {
    getCel(by, query);
  }, [by, query]);

  let pageTitle = query;
  document.title = "CybercelDB | " + pageTitle;

  return(
    <div className="detail-box">
    <h3 align="center">{pageTitle}</h3>

      <ul className="gallery mw-gallery-traditional">
        {/*
{ cels.length == 1 ? <p class="results">{cels.length} result</p> : <p class="results">{cels.length} results</p>}*/}
      {
          cels.map(cel => (
            <li key={cel.id} className="gallerybox">
                <div className="pinbox">
                  <Link to={"/celDetail/"+cel.id}>
                    <div className="thumb"><img alt={cel.name} className="cellist-image" src={"../cels/" + cel.img1}/></div>
                    <div className="celname">{cel.name} | {cel.rarity}</div>
                    </Link>
                </div>
           </li>
          ))
      }
      </ul>
      </div>
);
}
export default CelListSubset;