
import React, { useState, useEffect } from "react";
import CelDataService from "../services/cel";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import '../App.css'

const CelDetail = () => {
  let { query } = useParams();
const [cels, setCels] = useState([]);

const getCel = (query) => {
    CelDataService.find("id", query)
      .then(response => {
        setCels(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
 let celCharacters="";
 if (cels.character) {
  celCharacters = cels.character.split(", ");
}
  useEffect(() => {
    getCel(query);
  }, [query]);



  let pageTitle = query;
  document.title = "CybercelDB | " + pageTitle;

  return(
    <div className="detail-box">

      {
          cels.map(cel => (
                <div>
                    <h3 align="center">{cel.name}</h3>
                    <div align="center">
                      <a href={"../cels/" + cel.img2} target="_blank" rel="noreferrer"><img alt={cel.name} className="detail-image" src={"../cels/" + cel.img1}/></a>
                      <a href={"../cels/" + cel.img4} target="_blank" rel="noreferrer"><img alt={cel.name} className="detail-image" src={"../cels/" + cel.img3}/></a>
                    <p><b>Character</b>: <Link to={"/name/"+cel.name}>{cel.name}</Link><br/>
                    <b>Type</b>: <Link to={"/type/"+cel.type}>{cel.type}</Link><br/>
                    <b>ID</b>: {cel.id}<br/>
                    <b>Release Date</b>: {cel.release}<br/>
                    <b>Rarity</b>: <Link to={"/rarity/"+cel.rarity}>{cel.rarity}</Link><br/>
                    <b>Set</b>: <Link to={"/set/"+cel.set}>{cel.set}</Link><br/>
                    <b>Property</b>: <Link to={"/property/"+cel.property}>{cel.property}</Link></p>
                    </div>

                </div>

          ))
      }

      </div>
);


}
export default CelDetail;