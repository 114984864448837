import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CelDataService from "../services/cel";
import dayjs from "dayjs";

function Home() {
    const [news, setNews] = useState([]);

    const retrieveNews = () => {
        CelDataService.getNews()
          .then(response => {
            console.log(response.data);
            setNews(response.data);        
          })
          .catch(e => {
            console.log(e);
          });
      };
    
    useEffect(() => {
        retrieveNews();
    }, []);

    return (

<div class="right-sidebar-grid">
  <div class="main-content">
    <h3>Welcome to CybercelDB</h3>
    <p><img alt="sample cel" className="news-home-image" align="left" src="/cels/SXF-01-26-sm.png"/><b>What is Cybercel?</b> Cybercel is a collection of translucent animated art cels with a character themed border and back drop created by Collect Awesome, Inc. They are officially licensed anime art cards that are both a booklet card and trading card. The booklet can stay open to let the light shine through the art. 
    Scan the QR code on the back of the cel to add to your digital collection.</p>
    <p><b>What is this site?</b> CybercelDB is a fan-made database of information about Cybcercel. </p>
    <hr/>
    <h3>Updates</h3>
    {
          news.map (n => (
<>
<div className="newsbox">
    <Link to={"/newsDetail/"+n.id}>
        <img alt={n.headline} className="news-home-image" align="left" src={n.img1.replace(/^http:\/\//i, 'https://')}/><h4>{n.headline}</h4></Link><b>{dayjs(n.date).format("MM/DD/YY")}</b> - {n.summary}
        </div>
               <hr/>
               </>
          ))
      }


  </div>
  <div class="right-sidebar">
 <div align="center"> <Link to={"/property/My%20Hero%20Academia"}><img alt="My Hero Academia" src="mha-logo.png" className="home-property" align="center"/></Link></div>
 <div align="center"><Link to={"/property/Jujutsu%20Kaisen"}><img alt="Jujutsu Kaisen" src="jjk-logo.png" className="home-property" align="center"/></Link></div>
 <div align="center"><Link to={"/property/Spy%20X%20Family"}><img alt="Spy X Family" src="sxf-logo.png" className="home-property" align="center"/></Link></div>
 <div align="center"><Link to={"/property/Chainsaw%20Man"}><img alt="Chainsaw Man" src="csm-logo.png" className="home-property" align="center"/></Link></div>

 <div align="center"><Link to={"/property/Cyberpunk%20Edgerunners"}><img alt="Jujutsu Kaisen" src="cpe-logo.png" className="home-property" align="center"/></Link></div>
 <div align="center"><Link to={"/property/Dragon%20Ball%20Super"}><img alt="Spy X Family" src="dbs-logo.png" className="home-property" align="center"/></Link></div>
 <div align="center"><Link to={"/property/Five%20Nights%20at%20Freddy's"}><img alt="Chainsaw Man" src="fnf-logo.png" className="home-property" align="center"/></Link></div>
  </div>
</div>
    );
}

export default Home;
