import React, { useState, useEffect } from "react";
import CelDataService from "../services/cel";
import '../App.css'
import dayjs from "dayjs";

const NewsDetail = props => {

const [cels, setPin] = useState([]);

const getPin = newsNumber => {
  CelDataService.getNewsDetail(newsNumber)
    .then(response => {
      setPin(response.data);

    })
    .catch(e => {
      console.log(e);
     });
};

useEffect(() => {
  getPin(props.match.params.number);
}, [props.match.params.number]);
  
  function createMarkup() {
    return {__html: cels.body};
  }

  return(
    <div className="detail-box">
<h3 align="center">{cels.headline}</h3>
<p align="right" className="news-date">{dayjs(cels.date).format("MM/DD/YY")}</p>
<p className="news-body">
<div dangerouslySetInnerHTML={createMarkup()} />
</p>
      </div>
);
}
export default NewsDetail;