import React from "react";


function GlobalNav() {


  return (
    <div className="App">
<br/> 
 <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand" href="/"><img width="150" alt="CybercelDB" src="/ccdb-logo.png"></img></a>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Properties
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href="/property/Chainsaw Man">Chainsaw Man</a></li>
              <li><a className="dropdown-item" href="/property/Jujutsu Kaisen">Jujutsu Kaisen</a></li>
              <li><a className="dropdown-item" href="/property/My Hero Academia">My Hero Academia</a></li>
              <li><a className="dropdown-item" href="/property/Spy X Family">Spy X Family</a></li>   

              <li><a className="dropdown-item" href="/property/Cyberpunk Edgerunners">Cyberpunk Edgerunners</a></li>
              <li><a className="dropdown-item" href="/property/Dragon Ball Super">Dragon Ball Super</a></li>
              <li><a className="dropdown-item" href="/property/Five Nights at Freddy's">Five Nights at Freddy's</a></li>   
              </ul>
            </li>     
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Tags
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href="/type/Standard">Standard</a></li>
              <li><a className="dropdown-item" href="/type/XL">XL</a></li>
              <li><a className="dropdown-item" href="/rarity/Common">Common</a></li>
              <li><a className="dropdown-item" href="/rarity/Rare">Rare</a></li>
              <li><a className="dropdown-item" href="/rarity/Super Rare">Super Rare</a></li>
              <li><a className="dropdown-item" href="/rarity/Ultra Rare">Ultra Rare</a></li>
              <li><a className="dropdown-item" href="/rarity/Hyper Rare">Hyper Rare</a></li>

              </ul>
            </li> 
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Info
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href="/products">Products</a></li>
              <li><a className="dropdown-item" href="/where">Where To Buy</a></li>

              </ul>
            </li>             
            
            


          </ul>
        </div>
      </div>
    </nav>
    <br/>
  </div>

  );
}

export default GlobalNav;
