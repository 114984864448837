import React from "react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalNav from "./components/global-nav";
import Home from "./components/home";
import About from "./components/about";
import Where from "./components/where";
import Products from "./components/products";
import NewsDetail from "./components/news-detail";
import CelListSubset from "./components/cel-list-subset";
import CelDetail from "./components/cel-detail";


import './index.css'

const NoMatchPage = () => {
  return (
    <h3>404 - Not found</h3>
  );
};

function App() {

  return (
    <div>
      <GlobalNav/>
      <div>
        <Switch>
          <Route exact path={["/"]} component={Home} />   
          <Route exact path={["/where"]} component={Where} />      
          <Route exact path={["/products"]} component={Products} />      
          <Route exact path={["/celDetail/:query"]} component={CelDetail} />

          <Route 
            path="/newsDetail/:number"
            render={(props) => (
              <NewsDetail {...props} />
            )}
          />  
          <Route 
            path="/:by/:query"
            render={(props) => (
              <CelListSubset {...props} />
            )}
          />       

          <Route component={NoMatchPage} />             
        </Switch>
      </div>
      <About/>

    </div>
  );
}

export default App;