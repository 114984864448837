import http from "./http-common";

class CelDataService {

        getAll() {
            return http.get(`/celList`);
        }
        getNews() {
            return http.get(`/news`);
        }
        getNewsDetail(id) {
            return http.get(`/newsDetail?id=${id}`);
        } 
        get(id) {
            return http.get(`/celDetail?id=${id}`);
        } 
        find(by, query) {
           return http.get(`/cels?${by}=${query}`);
        //    return http.get(`/cels?set=My Hero Academia Series 1`);
        }    

    }     


export default new CelDataService();