import React from "react";

function Where() {

    return (
        <div className="detail-box">      
<h3 align="center">Where To Buy</h3>

<ul className="gallery mw-gallery-traditional">
<li className="gallerybox"><a title="Three If By Space" target="_blank" rel="noreferrer noopener" href="https://www.threeifbyspace.net/product-category/shop-by-brand/cybercel/?ref=515">
        <img alt="Three If By Space" className="where-image" src="logo_tibs.png"/></a>
    </li>   
    <li className="gallerybox"><a title="Pops and Pins" target="_blank" rel="noreferrer noopener" href="https://www.popsandpins.com/collections/cybercel/?ref=WBPMzPaO">
        <img alt="Pops and Pins" className="where-image" src="logo_popsandpins.png"/></a>
    </li>   

    <li className="gallerybox"><a title="Ralphie's Funhouse" target="_blank" rel="noreferrer noopener" href="https://ralphiesfunhouse.com/?ref=z6ju5rg3js">
        <img alt="Ralphie's Funhouse" className="where-image" src="logo_ralphies.png"/></a>
    </li> 
    <li className="gallerybox"><a title="Entertainment Earth" target="_blank" rel="noreferrer noopener" href="https://www.entertainmentearth.com/s/animation-cels/p?id=P0MJ53HDFA">
        <img alt="Entertainment Earth" className="where-image" src="logo_ee.png"/></a>
    </li>    
    <li className="gallerybox"><a title="Taggem" target="_blank" rel="noreferrer noopener" href="https://www.shoptaggem.com/collections/cybercel">
        <img alt="Taggem" className="where-image" src="logo_taggem.png"/></a>
    </li> 
    <li className="gallerybox"><a title="Bump-N-Bite" target="_blank" rel="noreferrer noopener" href="https://bump-n-bite.com/collections/trading-cards">
        <img alt="Bump-N-Bite" className="where-image" src="logo_bumpbite.png"/></a>
    </li>    

    <li className="gallerybox"><a title="Dave & Adam's Card World" target="_blank" rel="noreferrer noopener" href="https://www.dacardworld.com/search/?Search=cybercel">
        <img alt="Dave & Adam's Card World" className="where-image" src="logo_dacard.png"/></a>
    </li>
    <li className="gallerybox"><a title="ToyWiz" target="_blank" rel="noreferrer noopener" href="https://toywiz.com/search.php?s=cybercel">
        <img alt="ToyWiz" className="where-image" src="logo_toywiz.png"/></a>
    </li>
</ul>
<br/>
<h3 align="center">Distributors</h3>

<ul className="gallery mw-gallery-traditional">
<li className="gallerybox"><a title="GoGTS" target="_blank" rel="noreferrer noopener" href="https://gogts.net/">
        <img alt="GoGTS" className="where-image" src="logo_gogts.png"/></a>
    </li>   
    <li className="gallerybox"><a title="Southern Hobby" target="_blank" rel="noreferrer noopener" href="https://www.southernhobby.com/">
        <img alt="Southern Hobby" className="where-image" src="logo_shobby.png"/></a>
    </li>   
    <li className="gallerybox"><a title="Magazine Exchange" target="_blank" rel="noreferrer noopener" href="https://magazine-exchange.com/">
        <img alt="Magazine Exchange" className="where-image" src="logo_mx.png"/></a>
    </li>   
    <li className="gallerybox"><a title="Universal Distribution" target="_blank" rel="noreferrer noopener" href="https://www.universaldist.com/">
        <img alt="Universal Distribution" className="where-image" src="logo_udist.png"/></a>
    </li>   
</ul>
<br/>
<p align="center" className="legaltext">CybercelDB is user supported. When you purchase through links on our site, we may earn an affiliate commission.</p>
        </div>

 
    );
}

export default Where;