import React from "react";

function About() {
    return (
        <div className="footer">            
<p className="legal-text">CybercelDB is an unofficial fan site dedicated to Cybercel. This site is in no way affiliated with FiGPiN, Cybercel, or Collect Awesome, Inc. All copyrighted material is the property of its respective owners. The use of this material is intended for entertainment-only purposes. No copyright infringement is intended at all and items will be removed if the owners wish. Please direct any questions, comments, additions or corrections to cyberceldb@gmail.com.</p>
        </div>
    );
}

export default About;